<template>
    <div class="fullPageForm">

        <div class="loginFormHolder" v-if="!requested">
            <div class="loaderHolder" v-if="isLoading(['handleFindAccount'])"><PageLoader/></div>
            <form @submit.prevent="handleSubmit" class="form">
                <div class="loginTitle">Find your account</div>
                <Input v-model="form.email" :error="errors.email" name="Email address" type="text" placeholder="Email address"/>
                <button type="submit" class="default">Restore</button>
                <div class="forgotPasswordLink"><router-link to="/">Log in</router-link></div>
            </form>
        </div>

        <div class="loginFormHolder restore" v-else>
            <div class="textHolder">Check &nbsp;<strong>{{ form.email }}</strong>&nbsp; for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder or contact us.</div>
        </div>


        <div class="imageContenHolder">
            <div class="contentGrid">
                <img src="/images/logo-light.png" width="200">
                <div class="largeText">Welcome back</div>
                <div class="smallText">Log in now to get access to all your loads, income and statistics.</div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        title: "Restore Password",
        components: {

        },
        data() {
            return {
                form: {},
                errors: {},
                requested: false
            }
        },
        methods: {
            handleSubmit() {
                this.errors = {};

                this.ajax('handleFindAccount', {
                    method: 'POST',
                    url: '/restore',
                    data: this.form
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.requested = true;
                });

            }
        }
    }
</script>

<style lang="scss" scoped>
.form {
    display: grid;
    row-gap: 20px;
    width: 100%;
    max-width: 350px;
}

.fullPageForm {
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.loginFormHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 2;
    &.restore {
        .textHolder {
            max-width: 450px;
            text-align: center;
        }
    }
    .loaderHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255,255,255,0.5);
        z-index: 3;
    }
}

.imageContenHolder {
    background-position: center;
    background-size: cover;
    background-image: url('/images/login-bg.jpg');
    border-left: 1px solid $borderColor;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 30px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }
    .contentGrid {
        position: static;
        z-index: 2;
        display: grid;
        justify-content: center;
        justify-items: center;
        row-gap: 20px;
        text-align: center;
        .largeText {
            font-weight: 700;
            font-size: 36px;
            line-height: 54px;
            /* identical to box height */
            text-transform: uppercase;
            color: #FFFFFF;
            font-family: 'Poppins';
        }
        .smallText {
            max-width: 492px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;

            color: #FFFFFF;
        }
    }
}

.loginTitle {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 48px;
        height: 4px;
        background: $text;
        transform: translateY(10px);
    }
}

.forgotPasswordLink {
    text-align: center;
}
</style>